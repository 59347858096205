export const lv = {
    translation: {
        "Browse Product Catalog": "Pārlūkot produktu katalogu",
        "Show only items on stock": "Rādīt tikai preces noliktavā",
        "Choose a manufacturer": "Izvēlieties ražotāju",
        "Product Catalog": "Produktu katalogs",
        email: "E-pasts",
        submit: "Nosūtīt",
        login: {
            title: "Pieslēgšanās",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Lietotājvārds",
            password: "Parole",
            login: "Pieslēgšanās",
            forgotPassword: "Piemirsāt paroli?",
            companyRegistration: "Reģistrēties",
            tnc: "Terms & conditions",
            privacyPolicy: "Privātuma politika",
            rights: "® 1994 - 2021, Auto Kada. Visas tiesības aizsargātas",
            errorMessageCredentials:
                "Lūdzu ievadi korektu lietotājvārds un paroli personāla kontam. Ņem vērā, ka abi ievades lauki ir reģistr jūtīgi.",
            errorMessageIP: "Lietotāja kontam nav atļauts pieslēgties no dotās IP adreses",
            privacyPolicyEndorsement: "Noklikšķinot zemāk, jūs piekrītat mūsu",
            privacyPolicyAgreement: "Es piekrītu privātuma politikai.",
        },
        privacyPolicy: {
            title: "PERSONAS DATU AIZSARDZĪBA",
            bulletPoints: {
                1: "Puses šī Līguma ietvaros var veikt personu datu apstrādi Eiropas Parlamenta un padomes 2016. gada 27. aprīļa regulas 2016/679 par fizisku personu aizsardzību attiecībā uz personas datu apstrādi un šādu datu brīvu apriti.",
                2: "Puses apliecina un apņemas, ka informāciju (datus), ko tās iegūst viena no otras (tai skaitā - informāciju, datus no šīs mājas lapas, B2B sistēmas) bez otra puses piekrišanas nedrīkst nodot trešajām personām.",
                3: "Puses īsteno atbilstošus tehniskus un organizatoriskus pasākumus, lai nodrošinātu, ka pēc noklusējuma tiek apstrādāti tikai tādi personas dati, kas ir nepieciešami pušu darbībai šī Līguma ietvaros un katram konkrētajam apstrādes nolūkam. Minētais pienākums attiecas uz vākto un nodoto personas datu apjomu, to apstrādes pakāpi, glabāšanas laikposmu un to pieejamību.",
                4: "Puses nodrošina, pastāvīgi pārskata un uzlabo aizsardzības pasākumus, lai aizsargātu personas datus no nesankcionētas piekļuves, nejaušas nozaudēšanas, izpaušanas vai iznīcināšanas. Viena Puse neuzņemas atbildību par jebkādu nesankcionētu piekļuvi personas datiem un/ vai personas datu zudumu, ja tas nav atkarīgs no šīs Puses, piemēram, otras Puses vai trešās personas vainas un/ vai nolaidības dēļ.",
                5: "Personas datu aizsardzības pārkāpuma gadījumā, Pusei nekavējoties jāpaziņo par to otrai Pusei un bez nepamatotas kavēšanas jāpaziņo par to personas datu aizsardzības pārkāpumu kompetentajai uzraudzības iestādei (Datu valsts inspekcijai - www.dvi.gov.lv) ne vēlāk kā 72 stundu laikā no brīža, kad pārkāpums viņam kļuva zināms, izņemot gadījumus, kad ir maz ticams, ka personas datu aizsardzības pārkāpums varētu radīt risku fizisku personu tiesībām un brīvībām.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Piemirsāt paroli? Ievadiet zemāk savu e-pasta adresi, un mēs jums nosūtīsim instrukcijas jaunas paroles uzstādīšanai.",
                errorMsg: "Ievadiet korektu e-pasta adresi",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Esam nosūtījuši e-pastā instrukcijas paroles uzstādīšanai, ja konts ar ievadīto e-pasta adresi eksistē. Jūs tās saņemsiet pēc īsa brīža.",
                text2:
                    "Ja nesaņemat e-pastu, lūdzu pārliecinieties, ka ievadījāt pareizo e-pasta adresi, un pārbaudiet savu mēstuļu mapi.",
            },
            resetPasswordStep: {
                text:
                    "Lūdzu ievadiet savu jauno paroli divas reizes, lai varam pārliecināties, ka uzrakstījāt to pareizi.",
                newPassword: "Jauna parole",
                confirmPassword: "Apstiprināt paroli",
                submit: "Mainīt manu paroli",
                errorMsg: "Abi paroles lauki nesakrīt.",
            },
            invalidResetLinkStep: {
                text:
                    "Paroles atiestatīšanas saite ir nepareiza, iespējams tāpēc, ka tā jau ir izlietota. Lūdzu atkārtoti pieprasiet paroles atiestatīšanu.",
            },
            resetPasswordSuccessStep: {
                text:
                    "Jūsu parole ir uzstādīta. Varat turpināt ar pieslēgšanos.",
                login: "Pieslēgties",
            },
        },
        breadcrumbs: {
            backTitle: "Atpakaļ uz",
        },
        register: {
            title: "Reģistrēties",
            company: {
                subtitle: "Uzņēmums",
                name: "Nosaukums",
                registrationNumber: "Reģistrācijas numurs",
                vatRegistrationNr: "PVN maksātāja reģistrācijas numurs",
                address: "Adrese",
                city: "Pilsēta",
                postalCode: "Pasta indekss",
                country: "Valsts",
                chooseCountry: "Izvēlies savu valsti",
                email: "E-pasts",
                phoneNumber: "Tālruņa numurs",
            },
            user: {
                subtitle: "Lietotājs",
                usernameInputExplanation:
                    "Obligāts. Ne vairāk kā 150 simboli. Tikai burti, cipari un @/./+/-/_ .",
                password: "Parole",
                passwordConfirmation: "Paroles apstiprinājums",
                firstName: "Vārds",
                lastName: "Uzvārds",
                email: "E-pasta adrese",
            },
            register: "Reģistrēties",
            validationMessages: {
                name:
                    "Nosaukuma laukā jābūt vismaz 2 un ne vairāk kā 30 rakstzīmēm. Tajā nedrīkst būt īpašas rakstzīmes.",
                surname:
                    "Uzvārda laukā jābūt vismaz 2 un ne vairāk kā 30 rakstzīmēm. Tajā nedrīkst būt īpašas rakstzīmes.",
                required: "Šis lauks ir obligāts",
                userName: "Ievadiet derīgu lietotājvārdu",
                phoneNumber: "Tālruņa numuram jābūt 11 cipariem ar 0.",
                password:
                    "Jūsu parolei jābūt ne vairāk kā 20, vismaz 6 rakstzīmes.",
                confirmPassword: "Paroles nesakrīt",
                tos: "Lūdzu, izlasiet un apstipriniet lietotāja līgumu.",
                email: "Lūdzu ievadiet derīgu e-pasta adresi.",
            },
            registrationSuccess:
                "Reģistrācija ir pabeigta. Drīzumā ar jums sazināsies projektu vadītājs.",
        },
        headerUserMenu: {
            impersonateCustomer: "Uzdoties par klientu",
            stopImpersonation: "Beigt izlikšanos",
            switchLanguage: "Mainīt valodu",
            switchCountry: "Mainīt valsti",
            orderHistory: "Pasūtījumu vēsture",
            logout: "Beigt darbu",
        },
        header: {
            searchByCode: "OE/OEM/Ražotāja Nr.",
            searchByVehicle: "VIN numurs",
            searchByPlate: "Numurzīme",
            byCode: "Pēc koda",
            byVehicle: "Pēc transportlīdzekļa",
            byManufacturer: "Pēc ražotāja",
            byPlate: "Pēc numurzīmes",
            searchHistory: "MEKLĒŠANAS VĒSTURE",
            setAsDefaultCart: "Uzstādīt kā noklusēto grozu",
            removeDefaultCart: "Noņemt noklusēto grozu",
            emptyCart: "Iztukšot grozu",
            deleteCart: "Izdzēst grozu",
            confirm: "Apstiprināt",
            cancel: "Atcelt",
            emptyCartMessage: "Iztukšojot grozu visas pievienotās preces tiks izņemtas! Vai vēlaties turpināt?",
            deleteCartMessage: "Grozs tiks izdzēsts un visas pievienotās preces noņemtas! Vai vēlaties turpināt?",
            carByParameters: {
                title: "Automašīnas identifikācija pēc parametriem",
                listVehicles: "Uzskaitiet transportlīdzekļus",
                modifications: "Rādīt vairāk modifikāciju",
            },
            byVinFrame: {
                title: "Pēc VIN/Rāmja",
                tip: "Ievadiet Vin vai rāmja numuru",
                errorVehicleSearch: "Neizdevās atrast transportlīdzekli ar norādītajiem parametriem",
            },
            tags: {
                addTag: "Lūdzu, izvēlieties esošu tagu vai izveidojiet jaunu",
                inputTag: "Ievadiet tagu",
                createTag: "Izveidojiet un pievienojiet tagu",
            },
            comments: {
                addComment: "Lūdzu, pievienojiet komentāru",
                inputComment: "Ievadiet komentāru",
                createComment: "Izveidojiet un pievienojiet komentāru"
            }
        },
        sideMenu: {
            browseProductCatalog: "Produktu katalogs",
            newProducts: "Jaunie produkti",
            onlyItemsOnStock: "Rādīt tikai preces noliktavā",
            chooseAManufacturer: "Izvēlēties ražotāju",
        },
        notification: {
            actionName: {
                added: "pievienota grozam",
                removed: "izņemta no groza",
                addedFav: "pievienota izlasei",
                removedFav: "izņemta no izlases",
                removedAvailNotification: "noņemta no pieejamības paziņojumu saraksta",
                addedAvailNotification: "pievienota pieejamības paziņojumu sarakstam",
                cartEmptied: "Grozs ir iztukšots:",
                cartDeleted: "Grozs ir dzēsts:",
                cartDefaulted: "Iestatīt kā noklusējuma grozu:",
                cartUndefaulted: "Noņemts noklusējuma grozs:",
                addedTag: "pievienota tagam",
                tagDeleted: "Tags ir dzēsts:",
                commentSet: "Komentārs ir pievienots",
                commentRemoved: "Komentārs noņemts",
                commentExists: "Komentārs jau pastāv"
            },
            itemHasBeen: "Prece",
        },
        productsList: {
            fetching: "Notiek vairāk produktu iegūšana...",
            vehicle: "Transportlīdzeklis",
            productCatalog: "Preču katalogs",
            withDiscount: "Cenas ar atlaidi",
            withVAT: "Cenas ar PVN",
            noInfoError: "Nav informācijas sistēmā",
            availability: {
                green: "- Centrs/Latvija",
                yellow: "- Rīga/Latvija",
                orange: "- Latvija",
                grey: "- Pēc pasūtījuma",
                transit: "- Ceļā",
            },
            order: "Pasūtīt",
            successfulOrder: "Pasūtījums ir pieņemts.",
            orderHistory: {
                title: "Pasūtījumu vēsture",
                number: "Numurs",
                created: "Izveidots",
                state: "Stāvoklis",
                user: "Lietotājs",
                customer: "Klients",
                total: "Kopā",
                sourceLocation: "Sākuma noliktava",
                vendorCode: "Ražotāja kods",
                description: "Apraksts",
                unitPrice: "Vienības cena",
                count: "Skaits",
                return: "Atgriezties",
                delivery: "Piegāde",
                items: "Preces",
            },
            tableHeader: {
                image: "Attēls",
                product: "Prece",
                AKCode: "AK kods",
                oem: "OEM kods",
                vendor: "Ražotājs",
                stock: "Pieejamība",
                availability: "Pieejamība",
                price: "Cena",
                quantity: "Pievienot Grozam",
                comment: "Komentārs",
            },
            tableVehiclesHeader: {
                description: "apraksts",
                model: "Modeļa gads",
                build: "būvēt",
                tonnage: "Tonnāža",
                axle: "ass konfigurācija",
            },
            stockFlyout: {
                title: "KRĀJUMU PIEEJAMĪBA",
            },
            favouriteFlyout: {
                add: "Pievienot izlasei",
                remove: "Noņemt no izlases",
            },
            notificationFlyout: {
                add: "Pievienot pieejamības paziņojumu sarakstam",
                remove: "Noņemt no pieejamības paziņojumu saraksta",
            },
            commentFlyout: {
                add: "Pievieno komentāru",
            },
            tagFlyout: {
                add: "Pievienojiet tagus",
            },
            publicFlyout: {
                text: "Pieejamība, Pasūtīšana, OE kodi, Meklēšana pēc VIN nr. pieejami tikai reģistrētiem lietotājiem.",
                link: "Reģistrējies šeit",
            },
            cartButton: {
                title: "PIEVIENOT GROZAM",
                flyout: {
                    title:
                        "Lūdzu, izvēlieties esošu grozu vai izveidojiet jaunu",
                    cartName: "Groza nosaukums",
                    btnCaption: "IZVEIDOT UN PIEVIENOT PRECI",
                    inputPlaceholder: "Ievadiet groza nosaukumu",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Šīs preces nav nepieciešams pasūtīt, jo tām ir automātiska piegāde",
                quantity: "Skaits: ",
                totalWithoutVAT: "Kopā bez PVN:",
                VAT: "PVN",
                total: "Kopā"
            },
            sourceLocation: "Sākuma noliktava",
            deliveryType: {
                title: "Piegādes veids",
                deliveryAddress: {
                    title: "Piegādes adrese",
                    name: "Nosaukums",
                    address: "Adrese",
                    city: "Pilsēta",
                    postalCode: "Pasta indekss",
                    email: "E-pasts",
                    phone: "Tālrunis",
                    new: "Jauna",
                },
                pickup: "Saņemšanas vieta",
            },
            commentsTitle: "Piezīmes",
            user: "Lietotājs",
            date: "Datums",
            detailsModal: {
                tabs: {
                    alternativeProducts: "ALTERNATĪVIE PRODUKTI",
                    specification: "SPECIFIKĀCIJA",
                    oemCodes: "OEM KODS",
                    linkedVehicles: "SAISTĪTIE TRANSPORTLĪDZEKĻI",
                },
            },
        },
        appTour: {
            searchByCode: "B2B meklē gan pēc AUTO KADA krājuma kodiem, gan oriģinālo ražotāju numuriem",
            logoButton: "Lai nokļūtu atpakaļ uz pirmo skatu var izmantot AUTO KADA logo pogu vai norādi \"Atgriezties\"",
            grossPrices: "Rādīt veikala bruto cenas vai rādīt klienta cenas",
            vatPrices: "Rādīt cenas ar PVN vai bez PVN",
            productCatalog: "Produktu katalgos – ātrā meklēšanas sistēma pēc produktu grupām",
            // searchByVin: "Meklēšanas pēc VIN (Šasija nummura) izvēlne",
            languageMenu: "Valodas un pasūtījumu vēstures izvēlne",
            shoppingCart: "Pasūtījuma grozs",
            itemsInStock: "Rādīt tikai preces, kas ir noliktavā",
            selectedManufacturer: "Izvēlētais Ražotājs",
            notificationsAndFavorites: "Pievienojiet preci izlasei, nospiežot zobrata ikonu.\nPievienojiet preci paziņojumu sarakstam, nospiežot zvaniņa ikonu, lai tiktu informēts, kad prece atkal būs pieejama.\nSaraksti ar tiem pievienotajām precēm ir pieejami lapas augšpusē blakus pasūtījumu groza ikonai. Ja kāda no paziņojumu sarakstam pievienotajām precēm kļūs pieejama, tad pieejamo preču skaitu redzēsiet zaļā aplī blakus paziņojumu saraksta ikonai.",
            favorites: "Pievienojiet preci izlasei, nospiežot zobrata ikonu.\nSaraksts ar izlasei pievienotajām precēm ir pieejams lapas augšpusē, blakus pasūtījuma groza ikonai.",
            // manufacturersMenu: "Ražotāja izvēlne",
            searchParameters: "Izvēlētie meklēšanas parametri, meklēšana notiek tikai pēc šiem parametriem arī meklējot pēc AK vai OE koda",
            particularProduct: "Nospiežot uz konkrētā produkta atvērsies papildus logs, kur ir norādītikonkrēti šim nummuram saistītie alternatīvie produkti, ir parādīta tehniskāinformācija, bildes, orģinālie nummuri un modeļi, kam šis produkts ir paredzēts",
            searchDone: "Meklēšana notiek pēc pilna VIN (Šasijas nr.) ievadīšanas",
            openDetails: "Atverot meklēto rezerves daļu uz to nospiežot parāda AUTO KADA noliktavā pieejamos ražotājus, daudzumus un cenas",
            pictureNumbers: "Var meklēt arī spiežot uz numuriem, kas atrodās blakus interesējošai detaļai",
            addToCart: "Tieši no VIN kataloga ielikt preci pasūtījuma grozā.\n P.S. Lai būtu ērtāk strādāt var atvērt vairākus logus interneta pārlūkā, lai varētu meklēt pēc vairākiem VIN nr. vai vienlaicīgi meklējot citus AK vai OE numurus",
            chooseBasket: "Iespēja izvēlēties jau esošu vai taisīt jaunu grozu",
            basketContent: "Groza saturs, skaits - ar iespēju rediģēt, cenas, summas",
            deliveryMethod: "Piegādes veids, izvēloties AUTO KADA piegādi, pastu pirmo reizi būs jānorāda adrese un pēc tam adrese būs jānorāda, ja vēlēsieties piegādi uz citu atrašanās vietu",
            orderComments: "Piezīmes precīzākai pasūtījuma izpildei",
            replyTour: "Atkārtot instrukciju",
            next: "Nākamais",
            finish: "Pabeigt"
        },
        invoices: {
            availableCreditLimit: "Pieejamais kredīta limits",
            totalCreditLimit: "Kopējais kredīta limits",
            balance: "Bilance",
            latePayments: "Kavētie maksājumi",
            invoices: "Rēķini",
            invoiceDate: "Rēķina datums",
            invoiceNumber: "Rēķina numurs",
            totalValue: "Kopējā vērtība",
            remainingValue: "Atlikusī summa",
            dueDate: "Termiņš",
            status: "Statuss",
            documentNo: "Dokumenta nr",
            filter: "Filtrēt",
            discardFilters: "Noņemiet filtrus",
            from: "No",
            to: "Līdz",
            paid: "Apmaksāts",
            unpaid: "Neapmaksāts",
            partiallyPaid: "Daļēji apmaksāts",
            unpaidLate: "Neapmaksāts (kavēts)",
            partiallyPaidLate: "Daļēji apmaksāts (kavēts)",
            clear: "Notīrīt",
            cancel: "Atcelt",
            ok: "Labi"
        },
        autocomplete: {
            noOptions: "Nav atlases",
            open: "Atvērt",
            close: "Aizvērt"
        },
        dataGrid: {
            noResults: "Nav rezultātu"
        }
    },
};
