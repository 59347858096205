import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonHelp, TourOverlay, ButtonTour } from './style';
import { hideMenu, setSearchQuery, setSearchType, setSearchVisible, setSelectedVendors, showMenu, clearProductDetails } from '../../core/redux/actions/global';
import { SEARCH_TYPE } from '../Header/Search';
import { getAuthState } from '../../core/api/authHelper';
import { breakpoints } from '../../core/utils/style';

const TourControllerContext = createContext({
  currentStep: 0,
  skipTour: () => { },
  next: () => { }
});

function withTourController(WrappedComponent) {
  function WithTourController(props) {
    const { t } = useTranslation();
    const overlayRef = useRef();
    const isMobileScreen = useMediaQuery('(max-width:' + breakpoints.sm + ')');
    const closingTimeout = useRef(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);

    const userAuthenticatedStatus = useSelector(
      (state) => state.global.userAuthenticated
    );
    const userData = useSelector(
      (state) => state.global.userData
    );
    const authState = getAuthState();
    const userAuthenticated = userAuthenticatedStatus && authState?.accessToken;
    const history = useHistory();
    const dispatch = useDispatch();

    const isTourAkCode = userData && userData.tour_ak_code ? userData.tour_ak_code.is_code : false;
    const isBackupCode = userData && userData.tour_ak_code ? userData.tour_ak_code.backup_code : false;
    const tourAkCode = userData && userData.tour_ak_code ? userData.tour_ak_code.ak_code : "";

    useEffect(() => {
      (userData?.show_tour) && handleTourStart();
    }, [userData?.show_tour, isMobileScreen])

    const beforeStep = useCallback(async (nextStep) => {
      switch (nextStep) {
        case 0:
          await dispatch(clearProductDetails());
          await dispatch(setSelectedVendors([]));
          await dispatch(setSearchQuery(''));

          setShowOverlay(false);

          if (isMobileScreen) {
            await dispatch(hideMenu());
            await dispatch(setSearchVisible(false));
          }
          break;
        case 1:
          setShowOverlay(true);
          await dispatch(setSearchType(SEARCH_TYPE.byCode));

          if (isMobileScreen) {
            await dispatch(hideMenu());
            await dispatch(setSearchVisible(true));
          }
          break;
        case 5:
          if (isMobileScreen) {
            await dispatch(showMenu());
          }
          break;
        case 8:
          if (isMobileScreen) {
            await dispatch(showMenu());
          }
          break;
        case 10:
          await dispatch(setSelectedVendors([{ id: "1834", text: "AUTO KADA" }]));
          if (isMobileScreen) {
            await dispatch(hideMenu());
          }
          break;
        case 13:
          await dispatch(setSearchType(SEARCH_TYPE.byVehicle));
          await dispatch(setSearchQuery('YV2AG20D2CA721813'));
          if (isMobileScreen) {
            await dispatch(setSearchVisible(true))
          }
          break;
        case 14:
          history.push('/oem/YV2AG20D2CA721813/3068194');
          break;
        default:
          return;
      }
    }, [isMobileScreen, dispatch]);

    const afterStep = useCallback(async (lastStep) => {
      if (overlayRef.current) {
        overlayRef.current.style.clipPath = '';
      }

      switch (lastStep) {
        case 1:
          if (isMobileScreen) {
            await dispatch(setSearchVisible(false))
          }
          break;
        case 5:
          if (isMobileScreen) {
            await dispatch(hideMenu());
          }
          break;
        case 6:
          await dispatch(setSearchType(SEARCH_TYPE.byCode));
          if (isMobileScreen) {
            await dispatch(setSearchVisible(false))
          }
          break;
        case 11:
          if (isTourAkCode) {
            await dispatch(setSearchType(SEARCH_TYPE.byCode));
            if (isBackupCode) {
              await dispatch(setSelectedVendors([]));
              await dispatch(setSearchQuery(tourAkCode));
            } else {
              await dispatch(setSearchQuery(tourAkCode));
            }
          }
          if (isMobileScreen) {
            await dispatch(hideMenu());
          }
          break;
        case 12:
          await dispatch(setSelectedVendors([]));
          if (isMobileScreen) {
            await dispatch(hideMenu());
          }
          break;
        case 15:
          await dispatch(setSelectedVendors([]));
          if (isMobileScreen) {
            await dispatch(setSearchVisible(true))
          }
          await dispatch(setSearchType(SEARCH_TYPE.byCode));
          await dispatch(setSearchQuery(''))
          break;
        default:
          return;
      }
    }, [isMobileScreen, dispatch]);

    const next = useCallback(async () => {
      await afterStep(currentStep)
      await beforeStep(currentStep + 1)

      if (overlayRef.current) {
        overlayRef.current.style.clipPath = '';
      }

      setCurrentStep(currentStep + 1);
    }, [currentStep, beforeStep, afterStep])

    const handleMenu = (event) => {
      Boolean(anchorEl) ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleTourSkip = useCallback(() => {
      beforeStep(0).then(() => setCurrentStep(0));
      history.push('/home');
    }, [next, history])

    const handleTourStart = () => {
      handleMenuClose();
      beforeStep(0).then(() => setCurrentStep(0));
      history.push('/home');

      closingTimeout.current = setTimeout(() => {
        beforeStep(1)
          .then(() => setCurrentStep(1))
      }, 500)
    }

    useEffect(() => {
      return () => {
        clearTimeout(closingTimeout.current);
      }
    }, [])

    const contextValue = useMemo(() => ({
      currentStep,
      skipTour: handleTourSkip,
      next,
    }), [currentStep, handleTourSkip, next])

    if (!userAuthenticated) {
      return <WrappedComponent {...props} />
    }

    return (
      <TourControllerContext.Provider value={contextValue}>
        <WrappedComponent {...props} />

        {showOverlay && <TourOverlay ref={overlayRef} id="tour-overlay" />}
        {showOverlay && <TourOverlay transparent />}

        <ButtonHelp
          color={Boolean(anchorEl) ? 'secondary' : 'primary'}
          aria-controls="help-menu"
          aria-haspopup="true"
          aria-label="help menu"
          onClick={handleMenu}
        >
          {!Boolean(anchorEl) ? <HelpIcon /> : <CloseIcon />}
        </ButtonHelp>
        {Boolean(anchorEl) && <ButtonTour onClick={handleTourStart}>{t('appTour.replyTour')}</ButtonTour>}
      </TourControllerContext.Provider>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithTourController.displayName = `withTourController(${wrappedComponentName})`;

  return WithTourController;
}

export const useTourController = () => useContext(TourControllerContext);

export default withTourController;