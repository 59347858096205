export const lt = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "El. Paštas",
        submit: "Pateikti",
        login: {
            title: "Prisijungti",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Vartotojo vardas",
            password: "Slaptažodis",
            login: "Prisijungti",
            forgotPassword: "Pamirsai savo slaptazodi?",
            companyRegistration: "Registruotis",
            tnc: "Terms & conditions",
            privacyPolicy: "Privatumo politika",
            rights: "® 1994 - 2021, Auto Kada. Visos teisės saugomos",
            errorMessageCredentials:
                "Įveskite teisingą vartotojo vardas ir slaptažodį. Abiejuose laukuose didžiosios mažosios raidės skiriasi.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Paspausdami žemiau esančią registracijos nuorodą sutinkate su mūsų",
            privacyPolicyAgreement: "Sutinku su privatumo politika.",
        },
        privacyPolicy: {
            title: "ASMENS DUOMENŲ APSAUGA",
            bulletPoints: {
                1: "Remdamosi šia Sutartimi Šalys gali tvarkyti asmens duomenis pagal 2016 m. balandžio 27 d. Europos Parlamento ir Tarybos reglamentą 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir laisvo tokių duomenų judėjimo.",
                2: "Šalys patvirtina ir įsipareigoja, kad informaciją (duomenis), kurią gauna viena iš kitos (įskaitant – šios interneto svetainės, B2B sistemos informaciją, duomenis) Šalies, be kitos Šalies sutikimo draudžiama perduoti tretiesiems asmenims.",
                3: "Šalys įgyvendina tinkamas technines ir organizacines priemones, kad užtikrintų, jog pagal nutylėjimą tvarkomi tik tie asmens duomenys, kurie yra reikalingi šalių veiklai, remiantis šia Sutartimi, ir kiekvienam konkrečiam tikslui. Šis įsipareigojimas susijęs su renkamais bei perduodamais asmens duomenimis jų tvarkymo lygiu, saugojimo laikotarpiu ir jų prieinamumu.",
                4: "Šalys užtikrina, nuolatos peržiūri ir tobulina apsaugos priemones, siekdamos apsaugoti asmens duomenis nuo neteisėtos prieigos, atsitiktinio praradimo, atskleidimo ar sunaikinimo. Viena Šalis nėra atsakinga už bet kokią neteisėtą prieigą prie asmens duomenų ir (arba) jų praradimą, jei tai nepriklauso nuo šios Šalies, pavyzdžiui, dėl kitos Šalies ar trečiųjų asmenų kaltės ir (arba) aplaidumo.",
                5: "Asmens duomenų saugumo pažeidimo atveju, Šalis nedelsdama apie tai praneša kitai Šaliai ir nepagrįstai nedelsdama turi pranešti apie šį asmens duomenų saugumo pažeidimą kompetentingai priežiūros įstaigai (Valstybinei duomenų inspekcijai – www.dvi.gov.lv) ne vėliau kaip per 72 valandas nuo to momento, kai buvo sužinota apie pažeidimą, išskyrus atvejus, kai nėra tikėtina, kad dėl tokio asmens duomenų saugumo pažeidimo kils pavojus fizinių asmenų teisėms ir laisvėms.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Pamirsai savo slaptazodi? Žemiau įveskite savo el. Pašto adresą ir mes atsiųsime instrukcijas, kaip nustatyti naują.",
                errorMsg: "Įveskite teisingą el. pašto adresą.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "El. Paštu atsiuntėme instrukcijas, kaip nustatyti slaptažodį, jei su įvestu el. Pašto adresu yra paskyra. Netrukus turėtumėte juos gauti.",
                text2:
                    "Jei negaunate el. Laiško, įsitikinkite, kad įvedėte adresą, kurį užsiregistravote, ir patikrinkite savo SPAM aplanką.",
            },
            resetPasswordStep: {
                text:
                    "Du kartus įveskite naują slaptažodį, kad galėtume patvirtinti, kad įvedėte jį teisingai.",
                newPassword: "Naujas Slaptažodis",
                confirmPassword: "Patvirtinti slaptažodį",
                submit: "Pakeisti slaptažodį",
                errorMsg: "Du slaptažodžio laukai nesutampa.",
            },
            invalidResetLinkStep: {
                text:
                    "Slaptažodžio atkūrimo nuoroda buvo neteisinga, galbūt todėl, kad ji jau buvo naudojama. Prašykite naujo slaptažodžio nustatymo iš naujo.",
            },
            resetPasswordSuccessStep: {
                text:
                    "Jūsų slaptažodis buvo nustatytas. Galite eiti į priekį ir prisijungti dabar.",
                login: "Prisijungti",
            },
        },
        breadcrumbs: {
            backTitle: "Atgal į",
        },
        register: {
            title: "REGISTRACIJA",
            company: {
                subtitle: "BENDROVĖ",
                name: "vardas",
                registrationNumber: "Registracijos numeris",
                vatRegistrationNr: "VAT registration number",
                address: "Adresas",
                city: "Miestas",
                postalCode: "Pašto kodas",
                country: "Šalis",
                chooseCountry: "Pasirinkite savo šalį",
                email: "El. Paštas",
                phoneNumber: "Telefono numeris",
            },
            user: {
                subtitle: "VARTOTOJAS",
                usernameInputExplanation:
                    "Reikalingas 150 ar mažiau simbolių. Raidės, skaitmenys ir @. Tik + - _.",
                password: "Slaptažodis",
                passwordConfirmation: "slaptažodžio patvirtinimas",
                firstName: "Pirmas vardas",
                lastName: "Pavardė",
                email: "El. Paštas",
            },
            register: "REGISTRUOTIS",
            validationMessages: {
                name:
                    "Pavadinimo lauke turi būti mažiausiai 2 ir ne daugiau kaip 30 simbolių. Jame neturėtų būti specialių simbolių.",
                surname:
                    "Pavardės lauke turi būti mažiausiai 2 ir ne daugiau kaip 30 simbolių. Jame neturėtų būti specialių simbolių.",
                required: "Šį lauką būtina užpildyti",
                userName: "Įveskite galiojantį vartotojo vardą",
                phoneNumber:
                    "Telefono numeris turi būti 11 skaitmenų, o priekyje yra 0.",
                password:
                    "Jūsų slaptažodis turi būti ne didesnis kaip 20, mažiausiai 6 simboliai.",
                confirmPassword: "Slaptažodžiai nesutampa",
                tos: "Perskaitykite ir patvirtinkite vartotojo sutartį.",
                email: "Prašome įvesti galiojantį elektroninio pašto adresą.",
            },
            registrationSuccess:
                "Registracija baigta. Vadybininkas netrukus susisieks su jumis.",
        },
        headerUserMenu: {
            impersonateCustomer: "Apsimesti klientu",
            stopImpersonation: "Sustoti apsimesti",
            switchLanguage: "Keisti kalbą",
            switchCountry: "Pakeisti šalį",
            orderHistory: "Užsakymų Istorija",
            logout: "Atsijungti",
        },
        header: {
            searchByCode: "OE/OEM/tiekėjo numeris",
            searchByVehicle: "VIN numeris",
            searchByPlate: "Numerio lentelė",
            byCode: "Pagal kodą",
            byVehicle: "Transporto priemonę",
            byManufacturer: "Pagal gamintoją",
            byPlate: "Pagal numerio lentelė",
            searchHistory: "PAIEŠKOS ISTORIJA",
            setAsDefaultCart: "Nustatyti kaip numatytąjį krepšelį",
            removeDefaultCart: "Pašalinti numatytąjį krepšelį",
            emptyCart: "Tuščia krepšį",
            deleteCart: "Ištrinti krepšelį",
            confirm: "Patvirtinti",
            cancel: "Atšaukti",
            emptyCartMessage: "Ištuštinant krepšelį bus pašalintos visos jo prekės! Ar norite testi?",
            deleteCartMessage: "Krepšelis bus ištrintas ir visos jo prekės pašalintos! Ar norite testi?",
            carByParameters: {
                title: "Automobilio identifikavimas pagal parametrus",
                listVehicles: "Išvardykite transporto priemones",
                modifications: "Rodyti daugiau modifikacijų",
            },
            byVinFrame: {
                title: "Pagal VIN/rėmelį",
                tip: "Įveskite Vin arba rėmo numerį",
                errorVehicleSearch: "Nepavyko rasti transporto priemonės su pateiktais parametrais",
            },
            tags: {
                addTag: "Pasirinkite esamą žymą arba sukurkite naują",
                inputTag: "Įveskite žymą",
                createTag: "Sukurkite ir pridėkite žymą",
            },
            comments: {
                addComment: "Prašome pridėti komentarą",
                inputComment: "Įveskite komentarą",
                createComment: "Sukurkite ir pridėkite komentarą"
            }
        },
        sideMenu: {
            browseProductCatalog: "Prekių katalogą",
            newProducts: "Nauji produktai",
            onlyItemsOnStock: "Rodyti tik sandėlyje",
            chooseAManufacturer: "Pasirinkite tiekėją",
        },
        notification: {
            actionName: {
                added: "įtraukta į krepšelį",
                removed: "pašalinta iš krepšelio",
                addedFav: "įtrauktas į mėgstamiausius",
                removedFav: "pašalintas iš mėgstamiausių",
                removedAvailNotification: "pašalintas iš pranešimų apie prieinamumą sąrašo",
                addedAvailNotification: "įtraukta į pranešimų apie prieinamumą sąrašą",
                cartEmptied: "Krepšelis buvo ištuštintas:",
                cartDeleted: "Krepšelis buvo ištrintas:",
                cartDefaulted: "Nustatyti kaip numatytąjį krepšelį:",
                cartUndefaulted: "Pašalintas numatytasis krepšelis:",
                addedTag: "pridėta prie žymos",
                tagDeleted: "Žyma ištrinta:",
                commentSet: "Komentaras nustatytas",
                commentRemoved: "Komentaras pašalintas",
                commentExists: "Komentaras jau yra"
            },
            itemHasBeen: "Prekė",
        },
        productsList: {
            fetching: "Įkeliama daugiau produktų...",
            vehicle: "Transporto priemonė",
            productCatalog: "Prekių katalogas",
            withDiscount: "Kainos su nuolaida",
            withVAT: "Kainos su PVM",
            noInfoError: "Sistemoje informacijos nėra",
            availability: {
                green: "- Lietuva",
                yellow: "- Riga/Latvia",
                orange: "- Latvija",
                grey: "- Pagal pageidavimą",
                transit: "- Tranzitu",
            },
            order: "Užsakymas",
            successfulOrder: "Užsakymas priimtas.",
            orderHistory: {
                title: "Užsakymų Istorija",
                number: "Numeris",
                created: "Sukurta",
                state: "Situacija",
                user: "Vartotojas",
                customer: "Klientas",
                total: "Iš viso",
                sourceLocation: "Įkrovos sandėlis",
                vendorCode: "Pardavėjo kodas",
                description: "Aprašymas",
                unitPrice: "Vieneto kaina",
                count: "Skaičiuoti",
                return: "Grįžti atgal",
                delivery: "Pristatymas",
                items: "Elementai",
            },
            tableHeader: {
                image: "Vaizdas",
                product: "Produktas",
                AKCode: "AK kodas",
                oem: "OEM kodas",
                vendor: "Pardavėjas",
                stock: "Turimas",
                availability: "Prieinamumas",
                price: "Kaina",
                quantity: "Į krepšelį",
                comment: "Komentarą",
            },
            tableVehiclesHeader: {
                description: "apibūdinimas",
                model: "modelio metai",
                build: "statyti",
                tonnage: "TONNĒ",
                axle: "ašies konfigūracija\n",
            },
            stockFlyout: {
                title: "PREKIŲ PRIEINAMUMAS",
            },
            favouriteFlyout: {
                add: "Pridėti prie mėgstamiausių",
                remove: "Pašalinti iš mėgstamiausių",
            },
            notificationFlyout: {
                add: "Pridėti į pasiekiamumo pranešimų sąrašą",
                remove: "Pašalinti iš pasiekiamumo pranešimų sąrašo",
            },
            commentFlyout: {
                add: "Pridėti komentarą",
            },
            tagFlyout: {
                add: "Pridėti žymes",
            },
            publicFlyout: {
                text: "Prieinamumas, užsakymas, OE kodai, paieška pagal VIN Nr. prieinama tik registruotiems vartotojams.",
                link: "Registruotis čia",
            },
            cartButton: {
                title: "Į KREPŠELĮ",
                flyout: {
                    title: "Pasirinkite esamą krepšelį arba sukurkite naują",
                    cartName: "Krepšelio pavadinimas",
                    btnCaption: "SUKURTI IR PRIDĖTI PREK.",
                    inputPlaceholder: "Įveskite krepšelio pavadinimą",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Šių prekių užsakyti nebūtina, nes jų pristatymas yra automatinis",
                quantity: "Skaičiuoti: ",
                totalWithoutVAT: "Iš viso be PVM:",
                VAT: "PVM",
                total: "Iš viso"
            },
            sourceLocation: "Įkrovos sandėlis",
            deliveryType: {
                title: "Pristatymo tipas",
                deliveryAddress: {
                    title: "Pristatymo adresas",
                    name: "Pavadinimas",
                    address: "Adresas",
                    city: "Miestas",
                    postalCode: "Pašto kodas",
                    email: "El. Paštas",
                    phone: "Telefonas",
                    new: "Nauja",
                },
                pickup: "Paėmimo vieta",
            },
            commentsTitle: "Komentarai",
            user: "Vartotojas",
            date: "Data",
            detailsModal: {
                tabs: {
                    alternativeProducts: "ALTERNATYVINIAI PRODUKTAI",
                    specification: "SPECIFIKACIJA",
                    oemCodes: "OEM KODAI",
                    linkedVehicles: "SUSIJUSIOS TRANSPORTO PRIEMONĖS",
                },
            },
        },
        appTour: {
            searchByCode: "B2B ieško tiek pagal „AUTO KADA“ atsargų kodus, tiek pagal originalių gamintojų numerius",
            logoButton: "Norėdami grįžti į pirmąjį vaizdą, galite naudoti „AUTO KADA“ logotipo mygtuką arba nuorodą „Grįžti“",
            grossPrices: "Rodyti parduotuvės bendrąsias kainas arba kliento kainas",
            vatPrices: "Rodyti kainas su PVM arba be PVM",
            productCatalog: "Produktų katalogai – greita paieškos sistema pagal produktų grupes",
            // searchByVin: "Paieškos pagal VIN (važiuoklės numerį) meniu",
            languageMenu: "Kalbos ir užsakymų istorijos meniu",
            shoppingCart: "Užsakymo krepšelis",
            itemsInStock: "Rodyti tik sandėlyje esančias prekes",
            selectedManufacturer: "Pasirinktas gamintojas",
            notificationsAndFavorites: "Pridėkite produktą prie mėgstamiausių spustelėdami krumpliaračio piktogramą.\nPridėkite produktą prie pranešimų sąrašo spustelėdami varpelio piktogramą, kad būtų pranešta, kai jis vėl bus sandėlyje.\nJūsų pridėtų produktų sąrašai yra pasiekiami puslapio viršuje šalia pirkinių krepšelio piktogramos. Jei kuris nors iš produktų, įtrauktų į pranešimų sąrašą, bus pasiekiamas, šalia pranešimų sąrašo piktogramos žaliame apskritime matysite galimų produktų skaičių.",
            favorites: "Pridėkite produktą prie mėgstamiausių spustelėdami krumpliaračio piktogramą.\nJūsų mėgstamiausių produktų sąrašas pateikiamas puslapio viršuje šalia pirkinių krepšelio piktogramos.",
            // manufacturersMenu: "Gamintojo meniu",
            searchParameters: "Pasirinkti paieškos parametrai, paieška vykdoma tik pagal šiuos parametrus ir ieškant pagal AK arba OE kodą",
            particularProduct: "Paspaudus ant konkretaus produkto, bus atidarytas papildomas langas, kuriame bus rodomi konkretūs su šiuo numeriu susiję alternatyvūs produktai, pateikiama techninė informacija, nuotraukos, originalūs numeriai ir modeliai, kuriems šis gaminys skirtas.",
            searchDone: "Paieška atliekama įvedus visą VIN (važiuoklės numerį)",
            openDetails: "Atidarius ieškomą atsarginę dalį ir ant jos paspaudus, rodomi gamintojai, kainos ir kiekiai „AUTO KADA“ sandėlyje",
            pictureNumbers: "Galima ieškoti ir spaudžiant numerius, esančius šalia dominančios detalės",
            addToCart: "Nukreipkite prekę tiesiai iš VIN katalogo į užsakymo krepšelį.\n P.S. Kad būtų lengviau dirbti, interneto naršyklėje galima atidaryti kelis langus, kad galėtumėte ieškoti pagal kelis VIN numerius arba vienu metu ieškoti kitų AK ar OE numerių",
            chooseBasket: "Pasirinkite krepšelį arba sukurkite naują",
            basketContent: "Krepšelio turinys, kiekis – yra galimybė redaguoti, kainos, sumos",
            deliveryMethod: "Pristatymo būdas pasirenkant „AUTO KADA“ pristatymą, paštą – pirmą kartą turėsite pateikti adresą, o vėliau adresą turėsite nurodyti, tik jei pageidausite pristatyti kitu adresu",
            orderComments: "Pastabos dėl tikslesnio užsakymo vykdymo",
            replyTour: "Pakartoti nurodymus",
            next: "Kitas",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Galimas kredito limitas",
            totalCreditLimit: "Bendras kredito limitas",
            balance: "Balansas",
            latePayments: "Pavėluoti mokėjimai",
            invoices: "Sąskaitos",
            invoiceDate: "Sąskaitos data",
            invoiceNumber: "Sąskaitos numeris",
            totalValue: "Bendra vertė",
            remainingValue: "Likusi vertė",
            dueDate: "Terminas",
            status: "Būsena",
            documentNo: "Dokumentas Nr",
            filter: "Filtras",
            discardFilters: "Išmeskite filtrus",
            from: "Iš",
            to: "Iki",
            paid: "Apmokėtas",
            unpaid: "Neapmokėta",
            partiallyPaid: "Iš dalies apmokėta",
            unpaidLate: "Neapmokėta (pavėluota)",
            partiallyPaidLate: "Iš dalies apmokėta (pavėluota)",
            clear: "Išvalyti",
            cancel: "Atšaukti",
            ok: "Gerai"
        },
        autocomplete: {
            noOptions: "Jokių pasirinkimų",
            open: "Atidaryti",
            close: "Uždaryti"
        },
        dataGrid: {
            noResults: "Jokių rezultatų"
        }
    },
};
