export const ru = {
    translation: {
        "Welcome to React": "aaa Welcome to React and react-i18next",
        email: "Электронная почта",
        submit: "Отправить",
        login: {
            title: "Логин",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Имя пользователя",
            password: "Пароль",
            login: "Логин",
            forgotPassword: "Забыли пароль?",
            companyRegistration: "Зарегистрироваться",
            tnc: "Terms & conditions",
            privacyPolicy: "политика конфиденциальности",
            rights: "® 1994 - 2021, Auto Kada. все права защищены",
            errorMessageCredentials:
                "Пожалуйста, введите правильные имя пользователя и пароль. Оба поля могут быть чувствительны к регистру.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Нажимая кнопку ниже, чтобы зарегистрироваться, вы соглашаетесь с нашей",
            privacyPolicyAgreement:
                "Я согласен (-а) с политикой конфиденциальности.",
        },
        privacyPolicy: {
            title: "ЗАЩИТА ЛИЧНЫХ ДАННЫХ",
            bulletPoints: {
                1: "В рамках настоящего Договора Стороны вправе осуществлять обработку личных данных согласно Регламенту Европейского парламента и Совета № 2016/679 от 27 апреля 2016 года о защите физических лиц при обработке личных данных и свободном обороте таких данных.",
                2: "Стороны удостоверяют и обязуются не передавать информацию (данные), полученную ими друг от друга (в том числе информацию и данные с настоящей домашней страницы, из системы В2В), третьим лицам без согласия другой Стороны.",
                3: "Стороны осуществляют соответствующие технические и организационные мероприятия для обеспечения по умолчанию обработки только личных данных, необходимых для деятельности Сторон в рамках настоящего Договора и каждой конкретной цели обработки. Вышеуказанная обязанность относится к объему собранных и переданных личных данных, степени их обработки, длительности хранения и доступности.",
                4: "Стороны обеспечивают, постоянно пересматривают и совершенствуют мероприятия для защиты личных данных от несанкционированного доступа, случайной потери, разглашения или уничтожения. Одна Сторона не несет ответственности за любой несанкционированный доступ к личным данным и (или) потерю личных данных, не зависящие от данной Стороны, например, обусловленные виной и (или) халатностью другой Стороны или третьего лица.",
                5: "В случае нарушения защиты личных данных Сторона обязана немедленно сообщить об этом другой Стороне и без необоснованного промедления сообщить об этом компетентному органу по надзору за нарушениями защиты личных данных (Государственной инспекции данных – www.dvi.gov.lv) не позднее 72 часов с момента, когда Стороне стало известно о нарушении, за исключением случаев, когда возможность того, что нарушение защиты личных данных может угрожать правам и свободам физических лиц, маловероятна.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Забыли пароль? Введите ваш адрес электронной почты, и мы вышлем вам инструкции для установки нового пароля.",
                errorMsg: "Введите корректный адрес электронной почты.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Если существует учётная запись с указанным адресом электронной почты, то мы вышлем на него инструкции по установке пароля. В скором времени вы должны их получить.",
                text2:
                    "Если вы не получили письмо, убедитесь, что вы ввели адрес, который вы указывали при регистрации. Проверьте папку «спам».",
            },
            resetPasswordStep: {
                text:
                    "Введите, пожалуйста, пароль дважды, чтобы мы проверили правильность ввода.",
                newPassword: "Новый пароль",
                confirmPassword: "Подтвердить пароль",
                submit: "Изменить пароль",
                errorMsg: "Пароли, введенные в два поля, не совпадают.",
            },
            invalidResetLinkStep: {
                text:
                    "Возможно, ссылка на сброс пароля была недействительной, потому что ей уже пользовались. Запросите, пожалуйста, повторный сброс пароля.",
            },
            resetPasswordSuccessStep: {
                text:
                    "Ваш пароль установлен. Вы можете продолжать и войти на сайт.",
                login: "Авторизоваться",
            },
        },
        breadcrumbs: {
            backTitle: "Назад",
        },
        register: {
            title: "ПОСТАНОВКА НА УЧЕТ",
            company: {
                subtitle: "КОМПАНИЯ",
                name: "Имя",
                registrationNumber: "Регистрационный номер",
                vatRegistrationNr: "НДС регистрационный номер",
                address: "Адрес",
                city: "Город",
                postalCode: "Почтовый индекс",
                country: "Страна",
                chooseCountry: "Выберите вашу страну",
                email: "Электронное письмо",
                phoneNumber: "Телефонный номер",
            },
            user: {
                subtitle: "ПОЛЬЗОВАТЕЛЬ",
                usernameInputExplanation:
                    "Требуется не более 150 символов. Буквы, цифры и @. Только + - _.",
                password: "Пароль",
                passwordConfirmation: "Подтверждение пароля",
                firstName: "Имя",
                lastName: "Фамилия",
                email: "Электронное письмо",
            },
            register: "РЕГИСТР",
            validationMessages: {
                name:
                    "Поле имени должно содержать от 2 до 30 символов. Он не должен содержать специальных символов.",
                surname:
                    "Поле фамилии должно содержать от 2 до 30 символов. Он не должен содержать специальных символов.",
                required: "Это поле обязательно к заполнению",
                userName: "Введите действительное имя пользователя",
                phoneNumber:
                    "Номер телефона должен состоять из 11 цифр с 0 в начале.",
                password:
                    "Ваш пароль должен состоять максимум из 20, минимум из 6 символов.",
                confirmPassword: "Пароли не соответствуют",
                tos: "Прочтите и подтвердите пользовательское соглашение.",
                email:
                    "Пожалуйста, введите действительный адрес электронной почты.",
            },
            registrationSuccess:
                "Регистрация завершена. В скором времени с вами свяжется менеджер.",
        },
        headerUserMenu: {
            impersonateCustomer: "выдавать себя за клиента",
            stopImpersonation: "Выйти из режима «инкогнито»",
            switchLanguage: "Выбрать язык",
            switchCountry: "сменить страну",
            orderHistory: "История заказов",
            logout: "Выйти",
        },
        header: {
            searchByCode: "OE/OEM/leverandørnummer",
            searchByVehicle: "VIN номер",
            searchByPlate: "Номерной знак",
            byCode: "По коду",
            byVehicle: "По автомобилю",
            byManufacturer: "По производителю",
            byPlate: "По номерному знаку",
            searchHistory: "ИСТОРИЯ ПОИСКА",
            setAsDefaultCart: "Установить как корзину по умолчанию",
            removeDefaultCart: "Удалить корзину по умолчанию",
            emptyCart: "Очистить корзину",
            deleteCart: "Удалить корзину",
            confirm: "Подтверждать",
            cancel: "Отмена",
            emptyCartMessage: "Очистка корзины удалит все ее элементы! Вы хотите продолжить?",
            deleteCartMessage: "Корзина будет удалена, а все ее элементы удалены! Вы хотите продолжить?",
            carByParameters: {
                title: "Идентификация автомобиля по параметрам",
                listVehicles: "Список транспортных средств",
                modifications: "Показать больше модификаций",
            },
            byVinFrame: {
                title: "По VIN/раме",
                tip: "Введите Vin или номер фрейма",
                errorVehicleSearch: "Не удалось найти автомобиль с указанными параметрами",
            },
            tags: {
                addTag: "Пожалуйста, выберите существующую тег или создайте новую",
                inputTag: "Введите тег",
                createTag: "Создать и добавить тег",
            },
            comments: {
                addComment: "Пожалуйста, добавьте комментарий",
                inputComment: "Введите комментарий",
                createComment: "Создать и добавить комментарий"
            }
        },
        sideMenu: {
            browseProductCatalog: "каталога продукции",
            newProducts: "Новые продукты",
            onlyItemsOnStock: "Показать только в наличии",
            chooseAManufacturer: "Выбрать поставщика",
        },
        notification: {
            actionName: {
                added: "добавлена в корзину",
                removed: "удалена из корзины",
                addedFav: "добавлена ​​в избранное",
                removedFav: "удалена из избранного",
                removedAvailNotification: "удален из списка уведомлений о доступности",
                addedAvailNotification: "добавлен в список уведомлений о доступности",
                cartEmptied: "Корзина пуста:",
                cartDeleted: "Корзина удалена:",
                cartDefaulted: "Сделать корзиной по умолчанию:",
                cartUndefaulted: "Удалена корзина по умолчанию:",
                addedTag: "добавлено в тег",
                tagDeleted: "Тег удален:",
                commentSet: "Комментарий был установлен",
                commentRemoved: "Комментарий был удален",
                commentExists: "Комментарий уже существует"
            },
            itemHasBeen: "Позиция",
        },
        productsList: {
            fetching: "Загрузка дополнительных продуктов..",
            vehicle: "Автомобиль",
            productCatalog: "Каталог продукции",
            withDiscount: "Цены со скидкой",
            withVAT: "Цены с НДС",
            noInfoError: "В системе нет информации",
            availability: {
                green: "- Центр/Латвия",
                yellow: "- Рига/Латвия",
                orange: "- Латвия",
                grey: "- По запросу",
                transit: "- В пути",
            },
            order: "Заказ",
            successfulOrder: "Заказ принят.",
            orderHistory: {
                title: "История заказов",
                number: "Количество",
                created: "Создано",
                state: "Страна",
                user: "Пользователь",
                customer: "Клиент",
                total: "Итого",
                sourceLocation: "Исходное местоположение",
                vendorCode: "Код поставщика",
                description: "Описание",
                unitPrice: "Цена за единицу",
                count: "Подсчет",
                return: "Вернуться назад",
                delivery: "Доставка",
                items: "Позиции",
            },
            tableHeader: {
                image: "Изображение",
                product: "Продукт",
                AKCode: "Код AK",
                oem: "Код OEM",
                vendor: "Поставщик",
                stock: "Ассортимент товаров",
                availability: "Доступность",
                price: "Цена",
                quantity: "Добавить в корзину",
                comment: "Комментарий",
            },
            tableVehiclesHeader: {
                description: "Описание",
                model: "Год модели",
                build: "Шасси",
                tonnage: "Тоннаж",
                axle: "Конфигурация оси",
            },
            stockFlyout: {
                title: "НАЛИЧИЕ НА СКЛАДЕ",
            },
            favouriteFlyout: {
                add: "Добавить в избранное",
                remove: "Удалить из избранного",
            },
            notificationFlyout: {
                add: "Добавить в список уведомлений о доступности",
                remove: "Удалить из списка уведомлений о доступности",
            },
            commentFlyout: {
                add: "Добавить комментарий",
            },
            tagFlyout: {
                add: "Добавить теги",
            },
            publicFlyout: {
                text: "Наличие, заказ, оригинальные коды, поиск по VIN №. доступно только для зарегистрированных пользователей.",
                link: "Зарегистрируйтесь здесь",
            },
            cartButton: {
                title: "ДОБАВИТЬ В КОРЗИНУ",
                flyout: {
                    title:
                        "Пожалуйста, выберите существующую корзину или создайте новую",
                    cartName: "Название корзины",
                    btnCaption: "СОЗДАТЬ И ДОБАВИТЬ ПУНКТ",
                    inputPlaceholder: "Введите название корзины",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Нет необходимости заказывать эти товары, потому что у них есть автоматическая доставка",
                quantity: "Подсчет: ",
                totalWithoutVAT: "Итого, без НДС:",
                VAT: "НДС",
                total: "Итого"
            },
            sourceLocation: "Исходное местоположение",
            deliveryType: {
                title: "Тип доставки",
                deliveryAddress: {
                    title: "Адрес доставки",
                    name: "Имя",
                    address: "Адрес",
                    city: "Город",
                    postalCode: "Почтовый индекс",
                    email: "Электронная почта",
                    phone: "Телефон",
                    new: "Новый",
                },
                pickup: "Пункт выдачи заказов",
            },
            commentsTitle: "Комментарии",
            user: "Пользователь",
            date: "Дата",
            detailsModal: {
                tabs: {
                    alternativeProducts: "АЛЬТЕРНАТИВНЫЕ ПРОДУКТЫ",
                    specification: "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ",
                    oemCodes: "OEM КОДЫ",
                    linkedVehicles: "СВЯЗАННЫЕ ТРАНСПОРТНЫЕ СРЕДСТВА",
                },
            },
        },
        appTour: {
            searchByCode: "B2B ищет как по кодам коллекции AUTO KADA, так и по номерам оригинальных производителей",
            logoButton: "Чтобы вернуться к первому изображению, можно использовать кнопку логотипа AUTO KADA или указатель «Назад»",
            grossPrices: "Показывать брутто-цены магазина или показывать цены клиента",
            vatPrices: "Показывать цены с НДС или без НДС",
            productCatalog: "Каталог продуктов – система быстрого поиска по группам продуктов",
            // searchByVin: "Меню поиска по VIN (номер шасси).",
            languageMenu: "Меню языков и истории поиска",
            shoppingCart: "Корзина заказов",
            itemsInStock: "Показывать только товары, которые есть на складе",
            selectedManufacturer: "Выбранный Производитель",
            notificationsAndFavorites: "Добавьте товар в избранное, нажав на значок шестеренки.\nДобавьте товар в список уведомлений, нажав на значок колокольчика, чтобы получить уведомление, когда он снова появится на складе.\nСписки добавленных вами товаров доступны вверху страницы рядом со значком корзины. Если какой-либо из продуктов, добавленных в список уведомлений, станет доступным, вы увидите количество доступных продуктов, отображаемое в зеленом кружке рядом со значком списка уведомлений.",
            favorites: "Добавьте товар в избранное, нажав на значок шестеренки.\nСписок любимых товаров доступен вверху страницы рядом со значком корзины.",
            // manufacturersMenu: "Меню Производителя",
            searchParameters: "Выбранные параметры поиска ‒ поиск ведется только по этим параметрам также и в случае поиска по коду AK или OE",
            particularProduct: "При нажатии на конкретный продукт откроется дополнительное окно, где указаны связанные альтернативные продукты конкретно для этого номера, техническая информация, фотографии, оригинальные номера и модели, для которых этот продукт предназначен.",
            searchDone: "Поиск осуществляется после введения полного VIN (номера шасси)",
            openDetails: "При нажатии на искомую запасную часть открываются сведения о цене, а также об имеющемся на складе AUTO KADA количестве и доступных производителях",
            pictureNumbers: "Также можно вести поиск, нажимая на номера, находящиеся рядом с интересующей деталью",
            addToCart: "Положить товар в корзину заказов прямо из каталога VIN.\n P.S. Для удобства можно открыть в браузере несколько окон, чтобы искать по нескольким номерам VIN или одновременно ‒ другие номера AK или OE.",
            chooseBasket: "Выберите корзину или создайте новую",
            basketContent: "Содержимое корзины, количество – с возможностью редактирования, цены, суммы",
            deliveryMethod: "Вид доставки. В первый раз при выборе доставки AUTO KADA, почты нужно будет указать адрес, при последующих заказах адрес нужно будет указывать только в том случае, если Вы захотите изменить место доставки товара.",
            orderComments: "Примечания для более точного выполнения заказа",
            replyTour: "Повторить инструкцию",
            next: "Следующее",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Доступный кредитный лимит",
            totalCreditLimit: "Полный кредитный лимит",
            balance: "Остаток средств",
            latePayments: "Просроченные платежи",
            invoices: "Счета",
            invoiceDate: "Дата счета",
            invoiceNumber: "Номер счета",
            totalValue: "Общая стоимость",
            remainingValue: "Оставшееся значение",
            dueDate: "Срок сдачи",
            status: "Положение дел",
            documentNo: "Документ №",
            filter: "Фильтр",
            discardFilters: "Отменить фильтры",
            from: "С",
            to: "до",
            paid: "Оплаченный",
            unpaid: "Неоплачиваемый",
            partiallyPaid: "Частично оплачено",
            unpaidLate: "Неоплачиваемый (поздно)",
            partiallyPaidLate: "Частично оплачено (поздно)",
            clear: "Очистить",
            cancel: "Отмена",
            ok: "Хорошо"
        },
        autocomplete: {
            noOptions: "Нет выбора",
            open: "Открыть",
            close: "Закрывать"
        },
        dataGrid: {
            noResults: "Нет результатов"
        }
    },
};
