import styled, { css } from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { breakpoints } from "../core/utils/style";
import { theme } from "../core/styles/theme";

export const Wrapper = styled.div`
    padding: 20px 16px;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
        padding: 56px 25px 61px;
    }
`;

export const Title = styled.h3`
    color: #37474f;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;

    @media (min-width: ${breakpoints.md}) {
        text-align: center;
        font-size: 25px;
        margin-bottom: 36px;
    }
`;

export const SubTitle = styled.h4`
    color: #37474f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
    text-transform: uppercase;

    @media (min-width: ${breakpoints.md}) {
        flex: 0 0 100%;
        margin-bottom: 47px;
    }
`;

export const FormLabel = styled.label`
    color: #37474f;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;

    ${({ required }) =>
        required &&
        css`
            &:after {
                color: #f28a22;
                content: " *";
            }
        `}
`;

export const Input = styled.input`
    background: #ffffff;
    border: 1px solid #37474f;
    box-sizing: border-box;
    border-radius: 2px;
    line-height: 36px;

    padding: 0 14px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: var(--main-color);

    @media (min-width: ${breakpoints.md}) {
        line-height: 46px;
        max-width: 400px;
        width: 27.7777vw;
    }

    &::placeholder {
        font-size: 14px;
        color: #bcbcbc;
    }

    &:focus {
        border: 1px solid #f28a22;
    }

    ${({ error }) =>
        error &&
        css`
        border: 1px solid ${theme.colors.font.error};
    `}
`;

export const TextArea = styled.textarea`
    background: #ffffff;
    /* Main Color */

    border: 1px solid #37474f;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 26px 24px;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 150px;
    /* identical to box height, or 171% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: var(--main-color);
    width: 100%;

    &::placeholder {
        color: #bcbcbc;
        font-size: 14px;
    }
`;

export const InputExplanation = styled.small`
    font-weight: normal;
    font-size: 9px;
    line-height: 24px;
    /* or 267% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    /* Main Color */

    color: #37474f;

    @media (min-width: ${breakpoints.md}) {
        font-size: 10px;
    }
`;

export const ErrorMessage = styled.div`
    color: #cc0000;
    font-size: 11px;
    margin-top: 6px;
`;

export const SubmitButton = styled.button`
    cursor: pointer;
    height: 36px;
    display: flex;
    align-items: center;
    /* Secondary Color */

    background: #f28a22;
    border-radius: 2px;
    width: 100%;
    justify-content: center;

    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.15px;

    color: #ffffff;

    @media (min-width: ${breakpoints.md}) {
        flex: 0 0 100%;
        max-width: 280px;
        margin: 64px auto 0;
        height: 48px;

        &:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }
`;

export const Form = styled.form`
    @media (min-width: ${breakpoints.md}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

export const FormLine = styled.div`
    margin-bottom: 20px;
    position: relative;

    @media (min-width: ${breakpoints.md}) {
        margin-bottom: 24px;
    }
    ${({ hasExplanation }) =>
        hasExplanation &&
        css`
            margin-bottom: 6px;
            @media (min-width: ${breakpoints.md}) {
                margin-bottom: 10px;
            }
        `}
`;

export const RegisterSelectFormControl = styled(FormControl)`
    border: 1px solid #37474f !important;
    border-radius: 2px;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
        height: 48px;
        max-width: 400px;
        width: 27.7777vw;
    }

    fieldset {
        border: 0;
    }

    .MuiSelect-root {
        color: var(--main-color);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink,
    .PrivateNotchedOutline-legendNotched-4 {
        display: none;
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        color: #37474f;
        font-size: 14px;
        @media (min-width: ${breakpoints.md}) {
            line-height: 22px;
        }
    }

    .MuiSelect-outlined.MuiSelect-outlined {
        font-size: 14px;
        @media (min-width: ${breakpoints.md}) {
            height: 27px;
            display: flex;
            align-items: center;
        }
    }
`;

export const FormGroup = styled.div`
    &:first-of-type {
        @media (min-width: ${breakpoints.md}) {
            display: flex;
            flex: 0 0 58.9583vw;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    &:last-of-type {
        @media (min-width: ${breakpoints.md}) {
            display: flex;
            flex: 0 0 27.7777vw;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
`;

export const FormGroupInner = styled.div``;
