export const sv = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "E-post",
        submit: "Överlämna",
        login: {
            title: "Login",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Användarnamn",
            password: "Lösenord",
            login: "Login",
            forgotPassword: "Glömt lösenord?",
            companyRegistration: "Registrera",
            tnc: "Terms & conditions",
            privacyPolicy: "Integritetspolicy",
            rights: "® 1994 - 2021, Auto Kada. Alla rättigheter förbehållna",
            errorMessageCredentials:
                "Ange ett korrekt användarnamn och lösenord. Observera att båda fälten är skiftlägeskänsliga.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Genom att klicka nedan för att registrera dig godkänner du vår",
            privacyPolicyAgreement: "Jag godkänner integritetspolicyn.",
        },
        privacyPolicy: {
            title: "SKYDD AV PERSONUPPGIFTER",
            bulletPoints: {
                1: "Parterna kan inom ramen för detta avtal behandla personuppgifter i enlighet med Europaparlamentets och rådets förordning (EU) 2016/679 av den 27 april 2016 om skydd för fysiska personer med avseende på behandling av personuppgifter och om det fria flödet av sådana uppgifter.",
                2: "Parterna bekräftar och åtar sig att de uppgifter (data) de erhåller från varandra (inklusive information, data från denna webbplats, B2B-systemet) får inte överföras till tredje part utan den andra partens medgivande.",
                3: "Parterna ska vidta lämpliga tekniska och organisatoriska åtgärder för att säkerställa att som standard endast sådana personuppgifter som krävs för parternas verksamhet inom ramen för detta avtal och för varje specifikt behandlingsändamål behandlas. Denna skyldighet avser omfattningen av insamlade och överförda personuppgifter, deras behandlingsgrad, lagringsperiod och tillgänglighet.",
                4: "Parterna ska säkerställa, regelbundet granska och förbättra skyddsåtgärder för att skydda personuppgifter från otillåten tillgång, oavsiktlig förlust, avslöjande eller förstörelse. Den ena parten är inte ansvarig för obehörig tillgång till personuppgifter och/eller förlust av personuppgifter som inte orsakats av den nämnda parten utan beror på, t.ex. den andra partens eller en tredje parts fel och/eller försumlighet.",
                5: "Vid brott mot skydd av personuppgifter ska den ena parten omedelbart underrätta den andra parten och utan oskäligt dröjsmål underrätta den behöriga tillsynsmyndigheten som ansvarar för brott mot skydd av personuppgifter (Dataskyddsmyndigheten - www.dvi.gov.lv) senast 72 timmar efter det att brottet blev känt för parten, utom i fall där det är osannolikt att ett brott mot personuppgifter skulle kunna utgöra en risk för fysiska personers rättigheter och friheter.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Glömt lösenord? Ange din mailadress nedan. Du kommer sedan att få instruktioner för att ange nytt lösenord.",
                errorMsg: "Ange en giltig mejladress.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Vi har mailat dig instruktioner för att skapa lösenord till den e-mail adress du angivit. Då kommer att få detta mail inom kort.",
                text2:
                    "Om du ej har fått något mail, kontrollera att du angivit rätt mailadress. Kontrollera även din skräp mail låda.",
            },
            resetPasswordStep: {
                text:
                    "Skriv ditt nya lösenord två gånger för att verifiera detta.",
                newPassword: "Nytt lösenord",
                confirmPassword: "Bekräfta lösenord",
                submit: "Ändra lösenord",
                errorMsg: "Lösenord matchar inte.",
            },
            invalidResetLinkStep: {
                text:
                    "Länken för återställande av lösenord var ogiltig, då den redan använts. Vänlig begär ny återställning.",
            },
            resetPasswordSuccessStep: {
                text: "Ditt lösenord är registrerat. Du kan logga in nu.",
                login: "Log in",
            },
        },
        breadcrumbs: {
            backTitle: "Tillbaka till",
        },
        register: {
            title: "REGISTRERING",
            company: {
                subtitle: "FÖRETAG",
                name: "namn",
                registrationNumber: "Organisationsnummer",
                vatRegistrationNr: "Momsregistreringsnummer",
                address: "Adress",
                city: "Stad",
                postalCode: "Postnummer",
                country: "Land",
                chooseCountry: "Välj ditt land",
                email: "E-post",
                phoneNumber: "Telefonnummer",
            },
            user: {
                subtitle: "ANVÄNDARE",
                usernameInputExplanation:
                    "Krävs 150 tecken eller färre. Bokstäver, siffror och @. endast + - _.",
                password: "Lösenord",
                passwordConfirmation: "lösenordsbekräftelse",
                firstName: "Förnamn",
                lastName: "Efternamn",
                email: "E-post",
            },
            register: "REGISTRERA",
            validationMessages: {
                name:
                    "Namnfältet måste innehålla minst 2 och högst 30 tecken. Den bör inte innehålla specialtecken.",
                surname:
                    "Efternamnfältet måste innehålla minst 2 och högst 30 tecken. Den bör inte innehålla specialtecken.",
                required: "Detta fält krävs",
                userName: "Ange ett giltigt användarnamn",
                phoneNumber:
                    "Telefonnumret ska bestå av 11 siffror med ett ledande 0.",
                password: "Ditt lösenord måste vara max 20, min 6 tecken.",
                confirmPassword: "Lösenorden matchar inte",
                tos: "Läs och bekräfta användaravtalet.",
                email: "Ange en giltig e-postadress.",
            },
            registrationSuccess:
                "Registrering är komplett. Ansvarig kommer att kontakta dig inom kort.",
        },
        headerUserMenu: {
            impersonateCustomer: "Efterlikna kund",
            stopImpersonation: "Avsluta simulering",
            switchLanguage: "Ändra språk",
            switchCountry: "Växla land",
            orderHistory: "Orderhistorik",
            logout: "Logga ut",
        },
        header: {
            search: "",
            searchByCode: "OE/OEM/leverantörsnummer",
            searchByVehicle: "VIN numurs",
            searchByPlate: "Nummerplåt",
            byCode: "På kod",
            byVehicle: "På fordon",
            byManufacturer: "På tillverkare",
            byPlate: "På nummerplåt",
            searchHistory: "SÖKHISTORIK",
            setAsDefaultCart: "Ställ in som standardvagn",
            removeDefaultCart: "Ta bort standardvagnen",
            emptyCart: "Töm varukorg",
            deleteCart: "Radera varukorgen",
            confirm: "Bekräfta",
            cancel: "Annullera",
            emptyCartMessage: "Tömning av vagnen tar bort alla dess föremål! Vill du fortsätta?",
            deleteCartMessage: "Varukorgen kommer att raderas och alla dess föremål tas bort! Vill du fortsätta?",
            carByParameters: {
                title: "Identifiering av bil genom parametrar",
                listVehicles: "Lista fordonen",
                modifications: "Visa fler mods",
            },
            byVinFrame: {
                title: "På VIN/Frame",
                tip: "Ange VIN eller ramnummer",
                errorVehicleSearch: "Det gick inte att hitta fordonet med de angivna parametrarna",
            },
            tags: {
                addTag: "Välj en befintlig tagg eller skapa en ny",
                inputTag: "Ange en tagg",
                createTag: "Skapa och lägg till en tagg",
            },
            comments: {
                addComment: "Vänligen lägg till en kommentar",
                inputComment: "Skriv en kommentar",
                createComment: "Skapa och lägg till en kommentar"
            }
        },
        sideMenu: {
            browseProductCatalog: "Produktkatalog",
            newProducts: "Nya produkter",
            onlyItemsOnStock: "Visa endast i lager",
            chooseAManufacturer: "Välj Tillverkare",
        },
        notification: {
            actionName: {
                added: "har lagts till i varukorg",
                removed: "har tagits bort från varukorgen",
                addedFav: "har lagts till i favoriter",
                removedFav: "har tagits bort från favoriter",
                removedAvailNotification: "borttagen från listan med tillgänglighetsaviseringar",
                addedAvailNotification: "läggs till i listan över tillgänglighetsaviseringar",
                cartEmptied: "Varukorg har tömts:",
                cartDeleted: "Varukorg har raderats:",
                cartDefaulted: "Ställ in som varukorg:",
                cartUndefaulted: "Borttagen varukorg:",
                addedTag: "lagt till i taggen",
                tagDeleted: "Taggen har tagits bort:",
                commentSet: "Kommentaren är satt",
                commentRemoved: "Kommentaren har tagits bort",
                commentExists: "Kommentar finns redan"
            },
            itemHasBeen: "Artikel",
        },
        productsList: {
            fetching: "Laddar in fler produkter...",
            vehicle: "Fordon",
            productCatalog: "Produktkatalog",
            withDiscount: "Priser med rabatt",
            withVAT: "Priser med moms",
            noInfoError: "Det finns ingen information i systemet",
            availability: {
                green: "- Sverige",
                yellow: "- Riga",
                orange: "- Riga/ Lettland",
                grey: "- På begäran",
                transit: "- På väg",
            },
            order: "Beställning",
            successfulOrder: "Order har accepterats",
            orderHistory: {
                title: "Orderhistorik",
                number: "Antal",
                created: "Skapad",
                state: "Uppge",
                user: "Användare",
                customer: "Kund",
                total: "Summa",
                sourceLocation: "Beställs från",
                vendorCode: "Säljkod",
                description: "Beskrivning",
                unitPrice: "Styckpris",
                count: "Antal",
                return: "Returnera",
                delivery: "Leverans",
                items: "Artikel",
            },
            tableHeader: {
                image: "Bild",
                product: "Produkt",
                AKCode: "AK kod",
                oem: "OEM kod",
                vendor: "Tillverkare",
                stock: "Lager",
                availability: "Tillgänglighet",
                price: "Pris",
                quantity: "Lägg till i inköpsvagn",
                comment: "Kommentar",
            },
            tableVehiclesHeader: {
                description: "beskrivning",
                model: "ÅRSMODELL",
                build: "bygga",
                tonnage: "TONNAGE",
                axle: "AXELKONFIG",
            },
            stockFlyout: {
                title: "LAGERSTILLGÄNGLIGHET",
            },
            favouriteFlyout: {
                add: "Lägg till i favoriter",
                remove: "Ta bort från favoriter",
            },
            notificationFlyout: {
                add: "Lägg till i listan över tillgänglighetsmeddelanden",
                remove: "Ta bort från listan med tillgänglighetsaviseringar",
            },
            commentFlyout: {
                add: "Lägg till en kommentar",
            },
            tagFlyout: {
                add: "Lägg till taggar",
            },
            publicFlyout: {
                text: "Tillgänglighet, beställning, OE-koder, Sök med VIN-nr. endast tillgängligt för registrerade användare.",
                link: "Registrera dig här",
            },
            cartButton: {
                title: "LÄGG TILL I KUNDVAGN",
                flyout: {
                    title: "Välj befintlig vagn eller skapa ny",
                    cartName: "Kundvagnens namn",
                    btnCaption: "SKAPA OCH LÄGG TILL PUNKT",
                    inputPlaceholder: "Ange kundvagnens namn",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Det är inte nödvändigt att beställa dessa varor eftersom de har automatisk leverans",
                quantity: "Räkna: ",
                totalWithoutVAT: "Summa exklusive moms:",
                VAT: "Moms",
                total: "Summa"
            },
            sourceLocation: "Beställs från",
            deliveryType: {
                title: "Leveranssätt",
                deliveryAddress: {
                    title: "Leveransdress",
                    name: "Namn",
                    address: "Adress",
                    city: "Stad",
                    postalCode: "Postnummer",
                    email: "E-post",
                    phone: "Telefon",
                    new: "Ny",
                },
                pickup: "Avhämtningsadress",
            },
            commentsTitle: "Kommentarer",
            user: "Användare",
            date: "Datum",
            detailsModal: {
                tabs: {
                    alternativeProducts: "ALTERNATIVA PRODUKTER",
                    specification: "SPECIFIKATION",
                    oemCodes: "OEM-KODER",
                    linkedVehicles: "LÄNKADE FORDON",
                },
            },
        },
        appTour: {
            searchByCode: "B2B söker efter varor med både AUTO KADAs lagerkoder och originaltillverkarens nummer",
            logoButton: "För att återvända till startsidan, klicka på AUTO KADA-loggan eller på länken “Gå tillbaka”",
            grossPrices: "Visa bruttobelopp eller visa kundpriser",
            vatPrices: "Visa priser med eller utan moms",
            productCatalog: "Produktkatalogen har snabbsökning efter varugrupper",
            // searchByVin: "Sök med VIN-kod (chassinummer)",
            languageMenu: "Språk och orderhistorik",
            shoppingCart: "Varukorg",
            itemsInStock: "Visa endast varor på lager",
            selectedManufacturer: "Vald tillverkare",
            notificationsAndFavorites: "Lägg till produkten till dina favoriter genom att klicka på kugghjulsikonen.\nLägg till produkten till din notifikationslista genom att klicka på klockikonen för att få ett meddelande när den finns i lager igen.\nDina listor över tillagda produkter är tillgängliga längst upp på sidan bredvid kundvagnsikonen. Om någon av produkterna som läggs till i aviseringslistan blir tillgängliga kommer du att se ett antal tillgängliga produkter som visas i en grön cirkel bredvid ikonen för meddelandelistan.",
            favorites: "Lägg till produkten till dina favoriter genom att klicka på kugghjulsikonen.\nDin lista över favoritprodukter är tillgänglig högst upp på sidan bredvid kundvagnsikonen.",
            // manufacturersMenu: "Tillverkare",
            searchParameters: "Valda sökparametrar, sökning sker endast utefter dessa parametrar, även om man söker med AK-kod eller OE-nummer",
            particularProduct: "Om du klickar på en viss produkt öppnas ett ytterligare fönster som visar olika relaterade produkter, teknisk information, bilder, originalnummer och olika modeller för vilka produkten är avsedd.",
            searchDone: "Sökningen sker efter att du skrivit in en hel VIN-kod (chassinummer)",
            openDetails: "När du klickar på en sökt reservdel för att öppna den, visas de tillverkare, mängder och priser som finns tillgängliga på AUTO KADAs lager.",
            pictureNumbers: "Du kan också söka genom att trycka på siffrorna bredvid den detalj du är intresserad av.",
            addToCart: "Lägg till en vara i varukorgen direkt från VIN-katalogen. PS För att underlätta arbetet kan du öppna flera fönster eller flikar i en webbläsare för att söka efter flera VIN-koder eller samtidigt söka med andra Auto Kada-koder eller OE-nummer.",
            chooseBasket: "Välj korg eller skapa ny",
            basketContent: "Varukorgens innehåll, antal, med möjlighet att redigera priser, summor",
            deliveryMethod: "Leveranssätt, val emellan AUTO KADAs leverans eller post - första gången måste du ange adress och efter det behöver du bara ange adress om den ändrats.",
            orderComments: "Återkoppling för en bättre utförd beställning",
            replyTour: "Upprepa instruktionen",
            next: "Nästa",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Tillgänglig kreditgräns",
            totalCreditLimit: "Total kreditgräns",
            balance: "Balans",
            latePayments: "Sena betalningar",
            invoices: "Fakturor",
            invoiceDate: "Fakturadatum",
            invoiceNumber: "Fakturanummer",
            totalValue: "Totala värdet",
            remainingValue: "Återstående värde",
            dueDate: "Förfallodatum",
            status: "Status",
            documentNo: "Dokument nr",
            filter: "Filtrera",
            discardFilters: "Kassera filter",
            from: "Från",
            to: "Upp till",
            paid: "Betalt",
            unpaid: "Obetald",
            partiallyPaid: "Delvis betald",
            unpaidLate: "Obetald (sent)",
            partiallyPaidLate: "Delvis betald (sent)",
            clear: "Klar",
            cancel: "Annullera",
            ok: "Okej"
        },
        autocomplete: {
            noOptions: "Inga val",
            open: "Öppet",
            close: "Stänga"
        },
        dataGrid: {
            noResults: "Inga resultat"
        }
    },
};
